<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Appointments')" :description="$t('pages.advisor_appointments.page_desc')"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <bulk-action :actions="{export: 'Export'}" @perform-action="performBulkAction"></bulk-action>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search by')+' '+ $t('Appointment') +'#, '+$t('Name')+', '+$t('Email')"
                  :is-active="searchBarActive"
                  @do-search="searchLeads"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchLeads('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="lead in leads" :key="'apl'+lead.id">
                    <ion-item button
                              v-on:click="$router.push({name: 'LeadDetails', params:{id:lead.AL_id}})"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <ion-avatar slot="start" class="user-avatar">
                        <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                        <span v-else class="text-uppercase">{{ lead.first_name.substr(0,1) }}</span>
                      </ion-avatar>
                      <div>
                        <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                        <ion-text color="medium" class="medium d-block">#{{ lead.lead_number }}</ion-text>
                        <div class="lh-n medium" :style="{color: lead.status_color}">
                          <span>{{ $t(lead.status_text) }}</span>
                          <span class="d-block" v-if="lead.event_date && ['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
                              <span class="text-muted">{{$t('on')}}</span> {{lead.event_date}} <span class="text-muted">{{$t('at')}}</span> {{ lead.event_time}}
                            </span>
                        </div>
                      </div>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="adv_apt_s_all_cb" v-model="allSelected"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">{{$t('Appointment')}}#</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{$t('Information')}}</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Contact') }}</table-col>
                    <table-col break-point="md">{{ $t('Assign date') }}</table-col>
                    <table-col>{{ $t('Status') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads" :key="'ld'+lead.id" :class="{'unread': !lead.opened_time}">
                    <table-col has-check-box>
                      <b-check-box :id="'adv_apt_s1_cb'+lead.AL_id" v-model="lead.selected" :key="'adv_apt_s1_cbk'+lead.AL_id"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <router-link :to="{name: 'LeadDetails', params:{id: lead.AL_id}}">{{ lead.lead_number }}</router-link>
                    </table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :image="lead.dp" :initials="lead.initials" :user-email="lead.email">
                        <template v-slot:more_info>
                          <div class="lh-n mt-n1">
                            <span class="d-block d-md-none">Lead#: {{lead.lead_number}}</span>
                          </div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <div class="d-flex align-items-center">
                        <nio-icon icon="ni ni-map-pin-fill" class="mr-1"></nio-icon> <span>{{ lead.post_code+' '+lead.city}}{{ (lead.province.length) ? ', '+lead.province:''}}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <nio-icon icon="ni ni-call-alt-fill" class="mr-1"></nio-icon> <span>{{ lead.phone_no}}</span>
                      </div>
                    </table-col>
                    <table-col break-point="md">
                      {{lead.assign_date}}
                    </table-col>
                    <table-col>
                      <div class="lh-n" :style="{color: lead.status_color}">
                        <span class="fw-500">{{ $t(lead.status_text) }}</span>
                        <span class="d-block" v-if="lead.event_date && ['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
                              <span class="text-muted">{{$t('on')}}</span> {{lead.event_date}} <span class="text-muted">{{$t('at')}}</span> {{ lead.event_time}}
                            </span>
                      </div>
                    </table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" text="View Details" :to="{name: 'LeadDetails', params:{id:lead.AL_id}}"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <leads-csv-export-modal
        :selected-leads="leadsCSVExport.selectedLeads"
        :modal-open="leadsCSVExport.modalOpen"
        @modal-close="leadsCSVExport.modalOpen=false"></leads-csv-export-modal>
  </page-template>
</template>

<script>
import {IonSpinner, onIonViewWillEnter, IonList, IonItem, IonAvatar, IonLabel, IonText} from '@ionic/vue';
import {defineComponent, reactive, ref, watch, computed} from 'vue';
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";
import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import {DropDown,  } from "@core/components";
import { BlockContent, BlockHead, MainBlock} from "@core/layouts"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import "bootstrap"
import Pagination from "v-pagination-3"

import { useStore } from "vuex"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import useLeadFunctions from "@core/comp-functions/LeadFunctions"
import axios from "@/libs/axios"
import PageTemplate from "@core/layouts/page/PageTemplate";
import BulkAction from "@/views/components/BulkAction";
import useCommon from '@core/comp-functions/common'
import LeadsCsvExportModal from "@/views/leads-export/components/LeadsCsvExportModal";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";

export default defineComponent({
  components: {
    BCheckBox,
    LeadsCsvExportModal,
    BulkAction,
    PageTemplate,
    ListItem,
    DropDownList,
    UserSmallCard,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList, IonItem, IonAvatar, IonLabel, IonText,
  },
  setup() {

    const store   = useStore()
    const { cancelReturnRequest } = useLeadFunctions()
    const { updateTracker }     = useDataChangeTracker()
    const {selectAll, getSelectedRecordsForBulkAction} = useCommon()
    const leadStatuses = store.state.general_data.leads_statuses

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)
    let leads     = ref([])
    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    let leadsCSVExport = reactive({
      modalOpen: false,
      selectedLeads: [],
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async () => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/advisor/leads', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value,
            leads_type: 'appointments',
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            leads.value = resp.data.data
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('ReturnedLeads.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchLeads = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=> getLeads())

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.advisor.returned_leads, (n) => {
      if(n){
        updateTracker('advisor', ['returned_leads'], false) // reset tracker
        getLeads(true)
      }
    })
    const F_cancelReturnRequest = (AL_id) => {
      cancelReturnRequest(AL_id, (success) => {
        if(success){
          updateTracker('advisor', ['leads_list'])
          for(let x in leads.value){
            if(leads.value[x].AL_id === AL_id){
              leads.value.splice(x, 1)
              break
            }
          }
        }
      })
    }

    const performBulkAction = (action)=>{
      const list = getSelectedRecordsForBulkAction(leads.value)
      if(!list){
        return;
      }
      if( action === 'export' ){
        leadsCSVExport.selectedLeads.splice(0)
        for(let x in list) leadsCSVExport.selectedLeads.push(list[x].AL_id)
        leadsCSVExport.modalOpen = true
      }
    }

    const allSelected = computed({
      get:()=> {
        for(let x in leads.value){
          if(!leads.value[x].selected){
            return false
          }
        }
        return true
      },
      set: (s)=> selectAll(leads.value, s)
    })

    return {
      allSelected,
      F_cancelReturnRequest,
      getLeads,
      leadStatuses,
      leads,
      leadsCSVExport,
      loadingData,
      mandateFilter,
      order,
      pagination,
      paginationCallback,
      performBulkAction,
      searchBarActive,
      searchFilter,
      searchLeads,
      statusFilter,
    }
  }
});

</script>

<style scoped>
span.lr_reason_trim {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
</style>
